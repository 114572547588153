import PropTypes from 'prop-types';
import { Fragment, useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { deleteField, getAllFields } from '../../../actions/promotion/fields';
import { If } from 'react-if';
import BreadCrumb from '../../../components/Common/BreadCrumb';
import {
  Card,
  CardBody,
  Col,
  Container,
  CardHeader,
  Row,
  Button,
  Input,
  Spinner,
} from 'reactstrap';
import { formatDate } from '../../../helpers/formatDate';
import { formatTime } from '../../../helpers/formatTime';
import { Link } from 'react-router-dom';
import AddFields from './AddFields';
import AlertDelete from './AlertDelete';
import { alertError, alertSuccess } from '../../../helpers/errorHandling';

// ==============================|| Field - LIST ||============================== //

const PromotionFields = () => {
  const [data, setData] = useState([]);
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [field, setField] = useState(null);
  const [add, setAdd] = useState(false);
  const [loading, setLoading] = useState(false);
  const [meta, setMeta] = useState({});
  const [pageOffset, setPageOffset] = useState(0);

  const getFields = async () => {
    setLoading(true);
    const { data, meta } = await getAllFields({
      pageOffset: Math.max(pageOffset - 1, 0),
      pageSize: 10,
    });

    setTimeout(() => {
      setData(data);
      setMeta(meta);
      setLoading(false);
    }, 400);
  };

  useEffect(() => {
    getFields();
  }, [pageOffset]);

  const handleAdd = (f) => {
    if (f?._id) {
      setField({ ...f });
    } else {
      setField(null);
    }
    // navigate('/configurations/fields/addfields/new');
    setAdd(!add);

    // when closing and there is data in field object it get cleared
  };

  const handleClose = () => {
    setDeleteOpen(!deleteOpen);
  };

  const deleteUserHandler = async (fieldDeleteId) => {
    const res = await deleteField({ fieldId: fieldDeleteId });
    if (res?.deleteField?.success) {
      alertSuccess('Field deleted successfully');
      getFields();
      handleClose(true);
    } else {
      alertError('Failed!. Please try again later..');
    }
  };

  const handleRefreshData = () => {
    getFields();
  };
  const handlePageOffset = (value) => {
    setPageOffset(value);
  };
  const [showFieldModal, setEditFieldModal] = useState(null);
  return (
    <div className="page-content">
      <Container fluid>
        <BreadCrumb titleInActive={'Admin'} titleActive="Configurations" />
        <Card>
          <CardHeader className="border-0">
            <Row className="g-4 align-items-center">
              <Col sm></Col>
              <Col sm="auto">
                <div className="d-flex flex-wrap align-items-start gap-2">
                  <Button color="primary" size="sm" onClick={handleAdd}>
                    Create New Field
                  </Button>
                  <AddFields
                    fieldIdFromProps={'new'}
                    fieldDetailFromProp={null}
                    onCancel={handleAdd}
                    getFields={getFields}
                    show={add}
                  />
                </div>
              </Col>
            </Row>
          </CardHeader>
          <CardBody>
            <div className="table-responsive table-card">
              <table className="table table-nowrap table-centered align-middle mb-0">
                <thead className="bg-light text-muted">
                  <tr>
                    <th>Field Name</th>
                    <th>Field Code</th>
                    <th>Data Type</th>
                    <th>Field type</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {(data || []).map((field, key) => (
                    <tr key={key}>
                      <td>
                        <div className="d-flex align-items-center">
                          <div>
                            <h5 className="fs-13 my-1">{field?.fieldName}</h5>
                          </div>
                        </div>
                      </td>
                      <td>
                        <h5 className="fs-13 my-1 fw-normal">
                          {field?.fieldCode}
                        </h5>
                      </td>
                      <td>
                        <h5 className="fs-13 my-1 fw-normal">
                          {field?.dataType}
                        </h5>
                      </td>
                      <td>
                        <h5 className="fs-13 my-1 fw-normal">
                          {field?.fieldType}
                        </h5>
                      </td>

                      <td>
                        <div className="d-flex flex-row">
                          <div className="ms-2 me-2">
                            <If condition={showFieldModal === field?._id}>
                              <AddFields
                                fieldIdFromProps={field?._id}
                                fieldDetailFromProp={
                                  field ? { ...field } : null
                                }
                                onCancel={() => {
                                  setEditFieldModal(null);
                                }}
                                getFields={getFields}
                                show={true}
                              />
                            </If>
                            <Button
                              color="primary"
                              size="sm"
                              onClick={() => {
                                setEditFieldModal(field?._id);
                              }}
                            >
                              <i className="bx bx-edit fs-18"></i>
                            </Button>
                          </div>
                          <div>
                            <AlertDelete
                              deleteHandler={() =>
                                deleteUserHandler(field?._id)
                              }
                            />
                          </div>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </CardBody>
        </Card>
      </Container>
    </div>
  );
};

export default PromotionFields;
