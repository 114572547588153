import { useEffect, useState } from 'react';
import {
  FormGroup,
  Label,
  Input,
  FormFeedback,
  Button,
  Row,
  Col,
  Modal,
  ModalHeader,
  ModalBody,
} from 'reactstrap';

// third-party
import { Form, FormikProvider, useFormik } from 'formik';
import * as Yup from 'yup';
import { alertError, alertSuccess } from '../../../helpers/errorHandling';

// assets

import {
  createUpdateFields,
  getFieldDetail,
} from '../../../actions/promotion/fields';

import OptionValues from './OptionValues';
import { If } from 'react-if';

function getStyles(name, country, theme) {
  return {
    fontWeight:
      country?.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

// ==============================|| CUSTOMER - ADD / EDIT ||============================== //

const fieldGroups = {
  basket: { label: 'Basket', fieldTypes: [] },
  user: { label: 'User', fieldTypes: [] },
  item: { label: 'Item', fieldTypes: [] },
  derived: { label: 'Derived', fieldTypes: [] },
};
const fieldDataTypes = {
  string: { label: 'String', fieldTypes: [] },
  json: { label: 'JSON', fieldTypes: ['jsonValue'] },
  integer: { label: 'Integer', fieldTypes: ['number'] },
  float: { label: 'Float', fieldTypes: ['number'] },
  double: { label: 'Double', fieldTypes: ['number'] },
  boolean: { label: 'Boolean', fieldTypes: ['radio'] },
  datetime: { label: 'Datetime', fieldTypes: ['datetime', 'date'] },
};

const fieldTypes = {
  text: { label: 'Text Field' },
  number: { label: 'Number Field' },
  textarea: { label: 'Textarea' },
  multiselect: { label: 'Multi Select' },
  select: { label: 'Dropdown' },
  // image: { label: 'Image Upload' },
  datetime: { label: 'Date & Time' },
  date: { label: 'Date' },
  radio: { label: 'Single Select (Radio)' },
  checkbox: { label: 'Multi Select (Checkbox)' },
  switch: { label: 'Switch' },
  treeData: { label: 'Tree Picker' },
  jsonValue: { label: 'JSON values' },

  // "text" | "select" | "checkbox" | "radio" | "textarea" | "switch" | "multiselect" | null
};
const fieldGroupArray = Object.keys(fieldGroups).map((key) => ({
  label: fieldGroups[key].label,
  value: key,
}));
const fieldTypesArray = Object.keys(fieldTypes).map((key) => ({
  label: fieldTypes[key].label,
  value: key,
}));
const fieldDataTypesArray = Object.keys(fieldDataTypes).map((key) => ({
  label: fieldDataTypes[key].label,
  value: key,
  fieldTypes: fieldDataTypes[key].fieldTypes,
}));

const AddFields = ({
  getFields = () => {},
  fieldDetailFromProp = {},
  onCancel = () => {},
  show = false,
}) => {
  const fieldId = fieldDetailFromProp?._id || 'new';
  const isCreating = false;

  const [fieldDetail, setFieldDetail] = useState({ ...fieldDetailFromProp });

  const fetchFieldDetail = async (fieldId) => {
    const result = await getFieldDetail({ fieldId });
    if (result?.getFieldDetail?.optionValues) {
      result.getFieldDetail.optionValues =
        result?.getFieldDetail?.optionValues.map((option) => {
          delete option?.__typename;
          return option;
        });
    }

    setFieldDetail(result?.getFieldDetail);
  };
  useEffect(() => {
    if (fieldId && fieldId !== 'new') {
      fetchFieldDetail(fieldId);
    }
  }, [fieldDetailFromProp, fieldId]);

  const {
    fieldGroup = '',
    fieldName = '',
    fieldCode = '',
    dataType = 'string',
    fieldType = 'textfield',
    defaultValue = '',
    treeData = '',
    fieldUsedIn = [],
    isMultiple = false,
    enableSumQty = false,
    enableSumTotal = false,
    enableContains = false,
    optionValues = [],
    valueSource = [],
    errorMessages = '',
    placeholder = '',
  } = fieldDetail || {};
  const StoreSchema = Yup.object().shape({
    fieldGroup: Yup.string()
      .required('Field Group is required')
      .oneOf(Object.keys(fieldGroups)),
    fieldName: Yup.string()

      .max(100, 'Field Name must be at most 100 characters')
      .min(5, 'Field Name must be at least 5 characters')
      .required('Field Name is required')
      .matches(
        /^[a-zA-Z0-9 ]*$/,
        'Only letters, numbers, and spaces are allowed'
      ),
    fieldCode: Yup.string()
      .max(50)
      .min(2)
      .matches(
        /^[a-zA-Z0-9_\.]*$/,
        'Only letters, numbers, and underscores are allowed'
      ),
    dataType: Yup.string().oneOf(Object.keys(fieldDataTypes)),
    defaultValue: Yup.mixed().test({
      name: 'value-test',

      test: (value, t) => {
        // const selectedDatType = getFieldValues('dataType');
        switch (t.parent.dataType) {
          default:
          case 'string':
            return (
              typeof value === 'string' ||
              t.createError({
                message: 'Value must be a String',
                path: 'defaultValue',
              })
            );
          case 'integer':
            return (
              Number.isInteger(Number(value)) ||
              t.createError({
                message: 'Value must be a Integer',
                path: 'defaultValue',
              })
            );
          case 'float':
            return (
              typeof Number(value) === 'number' ||
              t.createError({
                message: 'Value must be a Float',
                path: 'defaultValue',
              })
            );
          case 'double':
            return (
              typeof Number(value) === 'number' ||
              t.createError({
                message: 'Value must be a number',
                path: 'defaultValue',
              })
            );
          case 'boolean':
            return (
              typeof value === 'boolean' ||
              t.createError({
                message: 'Value must be a boolean',
                path: 'defaultValue',
              })
            );
          case 'datetime':
            return (
              value instanceof Date ||
              t.createError({
                message: 'Value must be a DateTime',
                path: 'defaultValue',
              })
            );
        }
      },
    }),
    // .when('dataType', {
    //   is: 'boolean',
    //   then: Yup.boolean(),
    // }),
    // dateFormat: Yup.string().when('dataType', {
    //   is: 'datetime',
    //   then: Yup.string().required('Date time Format is required'),
    // }),
    fieldType: Yup.string().oneOf(Object.keys(fieldTypes)),
    // jsonValue: Yup.string().when('fieldType', {
    //   is: 'json',
    //   then: Yup.string().required('JSON value is required'),
    // }),
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      fieldGroup,
      fieldName,
      fieldCode,
      dataType,
      fieldType,
      defaultValue,
      treeData,
      fieldUsedIn,
      isMultiple,
      enableSumQty,
      enableSumTotal,
      enableContains,
      optionValues,
      valueSource,
      errorMessages,
      placeholder,
    },
    validationSchema: StoreSchema,
    onSubmit: async (values, { setSubmitting }) => {
      try {
        let success;
        let message;
        let error;
        if (fieldId) {
          const inputData = {
            ...values,
          };
          // Call create user API

          const {
            id,
            success: createStoreSuccess,
            message: createMessage,
            error: gqError,
          } = await createUpdateFields(values, fieldId);
          // message = createMessage || error.message;
          success = createStoreSuccess;
          if (createStoreSuccess) {
            getFields();
            //  setIsOpenModal(false);
            onCancel();
          }

          error = gqError;
        }
        if (success) {
          alertSuccess(
            isCreating
              ? 'Field created successfully.'
              : 'Field updated successfully.'
          );
          getFields();
        } else {
          alertError('Failed!. Please try again later..');
        }

        setSubmitting(false);
      } catch (error) {
        console.error(error);
      }
    },
  });

  const {
    errors,
    touched,
    handleSubmit,
    isSubmitting,
    getFieldProps,
    getFieldValues,
    setFieldValue,
  } = formik;

  // console.error(errors);
  const fieldTypeState = getFieldProps('fieldType');
  const dataTypeState = getFieldProps('dataType');
  const isMultipleProp = getFieldProps('isMultiple');
  const enableSumQtyProp = getFieldProps('enableSumQty');
  const enableSumTotalProp = getFieldProps('enableSumTotal');
  const enableContainsProp = getFieldProps('enableContains');
  console.log(`getFieldProps('isMultiple')`, getFieldProps('isMultiple'));
  return (
    <FormikProvider value={formik}>
      <Modal
        isOpen={show}
        toggle={() => {
          onCancel();
          // setIsOpenModal(!isOpenModal);
        }}
        centered={true}
        size="lg"
      >
        <ModalHeader
          className="modal-title pb-3 bg-light"
          toggle={() => {
            onCancel();
            // setIsOpenModal(!isOpenModal);
          }}
        >
          {fieldId === 'new' ? 'Create new field' : 'Edit Field'}
        </ModalHeader>

        <ModalBody>
          <Form autoComplete="off" onSubmit={handleSubmit}>
            <Row>
              <Col xs={12}>
                <Row>
                  <Col xs={6}>
                    <FormGroup>
                      <Label for="field-fieldGroup">Field Group</Label>
                      <Input
                        type="select"
                        id="fieldGroup"
                        {...getFieldProps('fieldGroup')}
                        invalid={touched.fieldGroup && !!errors.fieldGroup}
                      >
                        {fieldGroupArray.map((fieldGroupItem) => (
                          <option
                            key={fieldGroupItem.value}
                            value={fieldGroupItem.value}
                          >
                            {fieldGroupItem.label}
                          </option>
                        ))}
                      </Input>
                      {touched.fieldGroup && errors.fieldGroup && (
                        <FormFeedback>{errors.fieldGroup}</FormFeedback>
                      )}
                    </FormGroup>
                  </Col>
                  <Col xs={6}>
                    <FormGroup>
                      <Label for="field-name">Field Name</Label>
                      <Input
                        type="text"
                        id="field-name"
                        placeholder="Enter Field Name"
                        {...getFieldProps('fieldName')}
                        invalid={touched.fieldName && !!errors.fieldName}
                      />
                      {touched.fieldName && errors.fieldName && (
                        <FormFeedback>{errors.fieldName}</FormFeedback>
                      )}
                    </FormGroup>
                  </Col>
                  <Col xs={6}>
                    <FormGroup>
                      <Label for="field-code">Field Code</Label>
                      <Input
                        type="text"
                        id="field-code"
                        placeholder="Enter Field Code"
                        {...getFieldProps('fieldCode')}
                        invalid={touched.fieldCode && !!errors.fieldCode}
                      />
                      {touched.fieldCode && errors.fieldCode && (
                        <FormFeedback>{errors.fieldCode}</FormFeedback>
                      )}
                    </FormGroup>
                  </Col>
                  <Col xs={6}>
                    <FormGroup>
                      <Label for="field-dataType">Data Type</Label>
                      <Input
                        type="select"
                        id="dataType"
                        {...getFieldProps('dataType')}
                        invalid={touched.dataType && !!errors.dataType}
                      >
                        {fieldDataTypesArray.map((dataTypeItem) => (
                          <option
                            key={dataTypeItem.value}
                            value={dataTypeItem.value}
                          >
                            {dataTypeItem.label}
                          </option>
                        ))}
                      </Input>
                      {touched.dataType && errors.dataType && (
                        <FormFeedback>{errors.dataType}</FormFeedback>
                      )}
                    </FormGroup>
                  </Col>
                  <Col xs={6}>
                    <FormGroup>
                      <Label for="field-fieldType">Field Display Type</Label>
                      <Input
                        type="select"
                        id="fieldType"
                        {...getFieldProps('fieldType')}
                        invalid={touched.fieldType && !!errors.fieldType}
                      >
                        {fieldTypesArray.map((fieldTypeItem) => {
                          if (
                            fieldDataTypes?.[
                              dataTypeState?.value
                            ]?.fieldTypes?.includes(fieldTypeItem.value) ||
                            !fieldDataTypes?.[dataTypeState?.value]?.fieldTypes
                              ?.length
                          ) {
                            return (
                              <option
                                key={fieldTypeItem.value}
                                value={fieldTypeItem.value}
                              >
                                {fieldTypeItem.label}
                              </option>
                            );
                          }
                          return null;
                        })}
                      </Input>
                      {touched.fieldType && errors.fieldType && (
                        <FormFeedback>{errors.fieldType}</FormFeedback>
                      )}
                    </FormGroup>
                  </Col>
                  <Col xs={6}>
                    <FormGroup>
                      <Label for="default-value">Default Value</Label>
                      <Input
                        type="text"
                        id="default-value"
                        placeholder="Enter Default Value"
                        {...getFieldProps('defaultValue')}
                        invalid={touched.defaultValue && !!errors.defaultValue}
                      />
                      {touched.defaultValue && errors.defaultValue && (
                        <FormFeedback>{errors.defaultValue}</FormFeedback>
                      )}
                    </FormGroup>
                  </Col>
                  <Col xs={6}>
                    <FormGroup>
                      <Label for="placeholder-value">Placeholder</Label>
                      <Input
                        type="text"
                        id="placeholder-value"
                        placeholder="Enter Placeholder"
                        {...getFieldProps('placeholder')}
                        invalid={touched.placeholder && !!errors.placeholder}
                      />
                      {touched.placeholder && errors.placeholder && (
                        <FormFeedback>{errors.placeholder}</FormFeedback>
                      )}
                    </FormGroup>
                  </Col>
                  <If condition={fieldTypeState.value === 'treeData'}>
                    <Col xs={6}>
                      <FormGroup>
                        <Label for="tree-value">Tree Data</Label>
                        <Input
                          type="textarea"
                          id="tree-data"
                          placeholder="Enter Tree Data(JSON)"
                          {...getFieldProps('treeData')}
                          invalid={touched.treeData && !!errors.treeData}
                        />
                        {touched.treeData && errors.treeData && (
                          <FormFeedback>{errors.treeData}</FormFeedback>
                        )}
                      </FormGroup>
                    </Col>
                  </If>
                  <Col xs={12}>
                    <FormGroup>
                      <Label for="optionValues-value">Option Values</Label>
                      <OptionValues
                        {...getFieldProps('optionValues')}
                        onChange={(values) => {
                          setFieldValue('optionValues', values);
                        }}
                      />
                    </FormGroup>
                  </Col>
                  <Col xs={6}>
                    <FormGroup>
                      <Label for="errorMessages-value">
                        Default Error Message
                      </Label>
                      <Input
                        type="text"
                        id="errorMessages-value"
                        placeholder="Enter Error Message"
                        {...getFieldProps('errorMessages')}
                        invalid={
                          touched.errorMessages && !!errors.errorMessages
                        }
                      />
                      {touched.errorMessages && errors.errorMessages && (
                        <FormFeedback>{errors.errorMessages}</FormFeedback>
                      )}
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col xs={6}>
                    <FormGroup check>
                      <Label check>
                        <Input
                          type="checkbox"
                          id="is-multiple"
                          {...isMultipleProp}
                          checked={isMultipleProp.value}
                          // checked={values.isMultiple}
                          invalid={touched.isMultiple && !!errors.isMultiple}
                        />
                        Is Multiple
                      </Label>
                      {touched.isMultiple && errors.isMultiple && (
                        <FormFeedback>{errors.isMultiple}</FormFeedback>
                      )}
                    </FormGroup>
                    <FormGroup check>
                      <Label check>
                        <Input
                          type="checkbox"
                          id="enableSumQty"
                          {...getFieldProps('enableSumQty')}
                          checked={enableSumQtyProp?.value}
                          invalid={
                            touched.enableSumQty && !!errors.enableSumQty
                          }
                        />
                        Enable Count Sum Of Quantities
                      </Label>
                      {touched.enableSumQty && errors.enableSumQty && (
                        <FormFeedback>{errors.enableSumQty}</FormFeedback>
                      )}
                    </FormGroup>
                    <FormGroup check>
                      <Label check>
                        <Input
                          type="checkbox"
                          id="enableSumTotal"
                          {...getFieldProps('enableSumTotal')}
                          checked={enableSumTotalProp?.value}
                          invalid={
                            touched.enableSumTotal && !!errors.enableSumTotal
                          }
                        />
                        Enable Sub Total
                      </Label>
                      {touched.enableSumTotal && errors.enableSumTotal && (
                        <FormFeedback>{errors.enableSumTotal}</FormFeedback>
                      )}
                    </FormGroup>

                    <FormGroup check>
                      <Label check>
                        <Input
                          type="checkbox"
                          id="enableContains"
                          {...getFieldProps('enableContains')}
                          checked={enableContainsProp?.value}
                          invalid={
                            touched.enableContains && !!errors.enableContains
                          }
                        />
                        Enable Contains Check
                      </Label>
                      {touched.enableContains && errors.enableContains && (
                        <FormFeedback>{errors.enableContains}</FormFeedback>
                      )}
                    </FormGroup>
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row className="mt-4">
              <Col lg={12}>
                <div className="hstack gap-2 justify-content-end">
                  <Button color="light" onClick={onCancel} className="w-auto">
                    Close
                  </Button>
                  <Button
                    color="primary w-auto"
                    disabled={isSubmitting}
                    type="submit"
                  >
                    {fieldDetail?.fieldName ? 'Save Draft' : 'Save & Next'}
                  </Button>
                </div>
              </Col>
            </Row>
            {/* <Row>
              <Col className="text-end">
                <Button type="submit" color="primary" disabled={isSubmitting}>
                  {fieldDetail?.fieldName ? "Save Draft" : "Save & Next"}
                </Button>
              </Col>
            </Row> */}
          </Form>
        </ModalBody>
      </Modal>
    </FormikProvider>
  );
};

export default AddFields;
